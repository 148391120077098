import { ComponentProps, Fragment, ReactNode, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";

import AppointmentInfoList from "@/components/UI/Appointments/AppointmentInfoList";
import Button from "@/components/UI/Inputs/Button";

import { FORMS_DATA } from "@/constants";
import { AppointmentInfoProps } from "@/types";

import classes from "./VisitInfo.module.scss";

type ButtonInfo = { text: string | ReactNode } & ComponentProps<typeof Button>;

const VisitInfo = (props: {
  appointmentInfo: AppointmentInfoProps;
  buttons?: ButtonInfo[];
  isMobile?: boolean;
}): JSX.Element => {
  const [show, setShow] = useState(!props.isMobile);
  const ACTIVE_KEY = "visitInfo";

  const isActive = !props.isMobile || show;

  return (
    <Accordion activeKey={isActive ? ACTIVE_KEY : ""}>
      <div className={classes.panel}>
        <Row
          onClick={() => setShow((s) => !s)}
          style={{
            cursor: props.isMobile ? "pointer" : ""
          }}
        >
          <Col>
            <i className="fas fa-exclamation-triangle" />
            <Row>
              <Col>
                <h2>{FORMS_DATA.VISIT_INFO.CONFIRMATION} </h2>
              </Col>
              {props.isMobile && (
                <Col
                  sm={1}
                  xs={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className={`fas fa-chevron-${isActive ? "up" : "down"}`} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Accordion.Collapse eventKey={ACTIVE_KEY}>
          <>
            <Row>
              <Col>
                <AppointmentInfoList {...props.appointmentInfo} />
              </Col>
            </Row>
            <Row>
              <Col>
                {props.buttons &&
                  props.buttons.map((btn, i) => (
                    <Fragment key={i}>
                      <Button {...btn}>{btn.text}</Button>{" "}
                      <span className="mx-1" />
                    </Fragment>
                  ))}
              </Col>
            </Row>
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default VisitInfo;
