import { useRef } from "react";
import moment from "moment-timezone";

import DateMenu from "./DateMenu";
import DateScroll from "./DateScroll";
import { Menu, MenuRef } from "../Menu";
import { InputOpts } from "@/types";

import classes from "./DatePicker.module.scss";

const DatePicker = (
  props: {
    id: string;
    name: string;
    scrollDate?: boolean;
    count?: number;
    disabled?: boolean;
    className?: string;
    allowNull?: boolean;
    max?: moment.Moment;
    min?: moment.Moment;
  } & InputOpts
): JSX.Element => {
  const menuRef = useRef<MenuRef>(null);

  const selected = props.value ?? null;

  const selectedText = !!selected
    ? (selected as moment.Moment).format("MM | DD | YYYY")
    : "";

  return (
    <Menu
      id={props.id}
      ref={menuRef}
      icon="fas fa-calendar-alt"
      disabled={props.disabled}
      placeholder={props.name}
      valueText={selectedText}
      mobilePopup
      menuClassName={classes.menu}
      popupClassName={props.scrollDate ? "h-0" : ""}
      className={`
                     ${classes.date} 
                     ${props.className}
                 `}
    >
      {props.scrollDate ? (
        <DateScroll
          id={props.id}
          onConfirm={(d) => {
            if (props.onChange && (d !== null || !!props.allowNull))
              props.onChange(d);
            menuRef.current!.switchShow();
          }}
          initDate={props.value}
          minDate={props.min ?? moment(new Date(1900, 1, 1))}
          maxDate={props.max ?? moment(new Date(2050, 1, 1))}
        />
      ) : (
        <DateMenu
          onConfirm={(d) => {
            if (props.onChange && (d !== null || !!props.allowNull))
              props.onChange(d);
            menuRef.current!.switchShow();
          }}
          initDate={props.value ?? props.max}
          minDate={props.min ?? moment(new Date(1900, 1, 1))}
          maxDate={props.max ?? moment(new Date(2050, 1, 1))}
        />
      )}
    </Menu>
  );
};

export default DatePicker;
