import { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import _ from "@/lodash";

import Button from "@/components/UI/Inputs/Button";
import { arrayOf, getMonthName, getMonthNameInd, justDate } from "@/helpers";

import classes from "./DatePicker.module.scss";

enum DateMenuState {
  DAYS,
  MONTHS,
  YEARS
}

const DateMenuItem = (props: {
  value: string;
  disabled?: boolean;
  hidden?: boolean;
  active?: boolean;
  onClick?: () => void;
  onClickActive?: () => void;
}): JSX.Element => {
  const { active, hidden, onClickActive, onClick, value, disabled } = props;
  const _onClick = useCallback(() => {
    if (disabled || hidden) return;
    if (active && onClickActive) onClickActive();
    else if (onClick) onClick();
  }, [disabled, hidden, active, onClickActive, onClick]);

  return (
    <td
      onClick={_onClick}
      className={`
            ${active ? classes.active : ""}
            ${disabled ? classes.disabled : ""}
        `}
    >
      {!hidden && <>{value}</>}
    </td>
  );
};

const DateMenu = (props: {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  initDate?: moment.Moment | null;
  onConfirm: (d: moment.Moment | null) => void;
}): JSX.Element => {
  const [dmState, _setDMState] = useState(DateMenuState.DAYS);
  const setDMState = useCallback(
    (s: DateMenuState = dmState + 1) => {
      _setDMState(s % 3);
    },
    [dmState]
  );

  const minDate = justDate(props.minDate);
  const maxDate = justDate(props.maxDate);
  const initDate =
    props.initDate ?? justDate(_.max([moment(), props.minDate])!);

  const [selected, setSelected] = useState(initDate);
  const [showMonth, setShowMonth] = useState(selected.month());
  const [showYear, setShowYear] = useState(selected.year());
  const [showMaxYear, setShowMaxYear] = useState(selected.year());

  useEffect(() => {
    if (showMonth > 11) {
      setShowMonth(0);
      setShowYear((y) => y + 1);
    }
    if (showMonth < 0) {
      setShowMonth(11);
      setShowYear((y) => y - 1);
    }
  }, [showMonth]);

  const dStart = moment({ year: showYear, month: showMonth, date: 1 }, true);
  const dEnd = moment(dStart).endOf("month");

  let gridX = 0;
  let gridY = 0;
  let hasLeft = minDate < dStart;
  let hasRight = maxDate > dEnd;
  let clickHandleLeft = () => setShowMonth((m) => m - 1);
  let clickHandleRight = () => setShowMonth((m) => m + 1);

  switch (dmState) {
    case DateMenuState.DAYS:
      gridX = 7;
      gridY = 6;
      break;
    case DateMenuState.MONTHS:
      gridX = 3;
      gridY = 4;
      hasLeft = minDate.year() < showYear;
      hasRight = maxDate.year() > showYear;
      clickHandleLeft = () => {
        setShowYear((y) => y - 1);
      };
      clickHandleRight = () => setShowYear((y) => y + 1);
      break;
    case DateMenuState.YEARS:
      gridX = 3;
      gridY = 5;
      const perPage = gridY * gridX;
      hasLeft = minDate.year() < showMaxYear - perPage;
      hasRight = maxDate.year() > showMaxYear;
      clickHandleLeft = () => setShowMaxYear((y) => y - perPage);
      clickHandleRight = () => setShowMaxYear((y) => y + perPage);
      break;
  }

  const _onConfirm = props.onConfirm;
  const onConfirm = useCallback(
    (force?: moment.Moment | null) =>
      force === undefined
        ? _onConfirm(
            selected >= minDate && selected <= maxDate ? selected : null
          )
        : _onConfirm(force),
    [_onConfirm, minDate, maxDate, selected]
  );

  const renderFn = (i: number, j: number) => {
    const weekDay1 = dStart.isoWeekday();
    const weekDayMax = dEnd.date();

    switch (dmState) {
      case DateMenuState.DAYS:
        const ind = i * 7 + j - weekDay1 + 1;
        const d = justDate(
          moment({ year: showYear, month: showMonth, date: ind }, true)
        );

        return (
          <DateMenuItem
            key={j}
            onClick={() => setSelected(d)}
            onClickActive={onConfirm}
            disabled={d > props.maxDate || d < props.minDate}
            value={ind.toString()}
            active={d.isSame(selected, "day")}
            hidden={ind > weekDayMax || ind < 1}
          />
        );

      case DateMenuState.MONTHS:
        const m = gridX * i + j;
        return (
          <DateMenuItem
            key={j}
            value={getMonthNameInd(m).slice(0, 3)}
            active={selected.month() === m && showYear === selected.year()}
            disabled={
              (showYear === props.minDate.year() &&
                props.minDate.month() > m) ||
              (showYear === props.maxDate.year() && props.maxDate.month() < m)
            }
            onClick={() => {
              setSelected((prev) => {
                const d = moment(prev);
                d.set({
                  month: m,
                  year: showYear
                });
                return d;
              });
              setShowMonth(m);
            }}
          />
        );

      case DateMenuState.YEARS:
        const index = gridX * i + j;
        const maxY = showMaxYear - gridX * gridY + 1;
        const y = maxY + index;

        return (
          <DateMenuItem
            key={j}
            value={y.toString()}
            active={selected.year() === y}
            disabled={y < props.minDate.year() || y > props.maxDate.year()}
            onClick={() => {
              setSelected((prev) => moment(prev).year(y));
              setShowYear(y);
            }}
          />
        );
    }
  };

  return (
    <>
      <div className={classes.header}>
        <span
          className={hasLeft ? "" : classes.disabled}
          onClick={clickHandleLeft}
        >
          <i className="fas fa-chevron-left" />
        </span>
        <span
          className={hasRight ? "" : classes.disabled}
          onClick={clickHandleRight}
        >
          <i className="fas fa-chevron-right" />
        </span>

        <a onClick={() => setDMState()}>
          {dmState === DateMenuState.DAYS && getMonthName(dStart) + " "}
          {dmState === DateMenuState.YEARS &&
            showMaxYear - gridY * gridX + 1 + " - " + showMaxYear}
          {dmState !== DateMenuState.YEARS && showYear}
        </a>
      </div>

      <table className={classes.table}>
        {dmState === DateMenuState.DAYS && (
          <thead>
            <tr>
              <th>Su</th>
              <th>Mo</th>
              <th>Tu</th>
              <th>We</th>
              <th>Th</th>
              <th>Fr</th>
              <th>Sa</th>
            </tr>
          </thead>
        )}
        <tbody>
          {arrayOf(gridY).map((_, i) => (
            <tr key={i}>{arrayOf(gridX).map((__, j) => renderFn(i, j))}</tr>
          ))}
        </tbody>
      </table>

      <div className={classes.buttons}>
        <span onClick={() => onConfirm(null)}>
          <i className="fas fa-trash" />
        </span>
        <Button onClick={() => onConfirm()} disabled={!selected} noAnimate>
          Confirm
        </Button>
      </div>
    </>
  );
};
export default DateMenu;
