import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment-timezone";

import Select from "../Select";
import Button from "../Button";
import {
  daysOfMonth,
  getMonthNameInd,
  getNumberOptions,
  justDate
} from "@/helpers";

import classes from "./DatePicker.module.scss";

const DateScroll = (props: {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  initDate?: moment.Moment;
  onConfirm?: (d: moment.Moment | null) => void;
  id: string;
}): JSX.Element => {
  const minDate = justDate(props.minDate);
  const maxDate = justDate(props.maxDate);
  const initDate = props.initDate ?? moment(maxDate);

  const [date, setDate] = useState(initDate);

  const dateSafe = (d: moment.Moment) => {
    if (d < minDate) return moment(minDate);
    if (d > maxDate) return moment(maxDate);
    return d;
  };

  const setDay = (day: number) => setDate((d) => dateSafe(moment(d).date(day)));
  const setMonth = (month: number) =>
    setDate((d) => dateSafe(moment(d).month(month)));
  const setYear = (year: number) =>
    setDate((d) => dateSafe(moment(d).year(year)));

  // EXPERIMENTAL
  /*
    const isMobile = useIsMobile();
    useEffect(() => {
        if (isMobile || !props.onConfirm) return;

        // props.onConfirm(date);
    }, [isMobile, props.onConfirm, date]);
    */
  const yearBounds: [number, number] = [minDate.year(), maxDate.year()];
  const monthBounds: [number, number] = [
    minDate.year() === date.year() ? minDate.month() : 0,
    maxDate.year() === date.year() ? maxDate.month() : 11
  ];
  const dayBounds: [number, number] = [
    minDate.year() === date.year() && minDate.month() === date.month()
      ? minDate.date()
      : 1,
    maxDate.year() === date.year() && maxDate.month() === date.month()
      ? maxDate.date()
      : daysOfMonth(date)
  ];

  return (
    <Col className={classes.scrollDate}>
      <Row className={classes.controls}>
        <Col xs={5}>
          <Select
            id={`${props.id}_month`}
            name=""
            options={getNumberOptions(monthBounds).map((i) => ({
              ...i,
              text: getMonthNameInd(i.value)
            }))}
            autoSelect
            value={date.month()}
            onChange={setMonth}
          />
        </Col>
        <Col xs={3}>
          <Select
            id={`${props.id}_day`}
            name=""
            options={getNumberOptions(dayBounds)}
            autoSelect
            value={date.date()}
            onChange={setDay}
          />
        </Col>
        <Col xs={4}>
          <Select
            id={`${props.id}_year`}
            name=""
            options={getNumberOptions(yearBounds).reverse()}
            autoSelect
            value={date.year()}
            onChange={setYear}
          />
        </Col>
      </Row>
      <Row className="d-md-block d-block my-1">
        <Button
          noAnimate
          onClick={() => {
            if (!props.onConfirm) return;

            props.onConfirm(date > maxDate || date < minDate ? null : date);
          }}
        >
          Confirm
        </Button>
      </Row>
    </Col>
  );
};
export default DateScroll;
