import React, {
  CSSProperties,
  HTMLInputTypeAttribute,
  MouseEventHandler,
  ReactNode,
  useState
} from "react";

import { isNotNull } from "@/helpers";
import { InputOpts, ValidatorObject } from "@/types";

import classes from "./Input.module.scss";
import { requestAnimationFrame } from "dom-helpers";

const Input = (
  props: {
    id?: string;
    type?: HTMLInputTypeAttribute;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    className?: string;
    clickable?: boolean;
    onClick?: MouseEventHandler<HTMLInputElement>;
    onFocus?: () => void;
    onBlur?: (isInside: boolean) => void;
    onInputBlur?: () => void;
    onError?: (err: string) => void;
    autoFocus?: boolean;
    icon?: string;
    children?: ReactNode;
    loading?: boolean;
    prefilled?: boolean;
    style?: CSSProperties;
    forceUppercase?: boolean;
    ariaLabel?: string;
  } & InputOpts &
    ValidatorObject
): JSX.Element => {
  const [error, setError] = useState(
    !!props.validator && isNotNull(props.value) && !props.prefilled
      ? props.validator(props.value)
      : ""
  );
  const [_showError, setShowError] = useState(!!error);
  const showError = _showError && !props.disabled;
  const [touched, setTouched] = useState(false);

  return (
    <div
      id={props.id}
      className={`
                    ${classes.input} 
                    ${props.clickable ? classes.clickable : ""}
                    ${props.loading ? classes.loading : ""} 
                    ${props.className}  
                `}
      onFocus={() => {
        if (props.onFocus) props.onFocus();
      }}
      onBlur={(event) => {
        const currentTarget = event.currentTarget;
        const relatedTarget = event.relatedTarget;
        requestAnimationFrame(() => {
          const isInside = currentTarget.contains(document.activeElement);
          if (props.onBlur) props.onBlur(isInside || relatedTarget === null);
        });
      }}
    >
      <input
        style={{
          textTransform: props.forceUppercase ? "uppercase" : "none",
          ...(props.style ?? {})
        }}
        type={props.type}
        name={props.name}
        required={props.required}
        min={props.min}
        max={props.max}
        minLength={props.minLength}
        maxLength={props.maxLength}
        autoFocus={props.autoFocus}
        autoComplete="chrome-off"
        // pattern={props.pattern?.source}
        disabled={props.disabled}
        readOnly={props.readOnly}
        value={props.value}
        onClick={
          !props.loading
            ? (e) => {
                if (props.onClick) props.onClick(e);
              }
            : undefined
        }
        onBlur={() => {
          if (props.onInputBlur) props.onInputBlur();
          setShowError(!!error);
        }}
        onChange={(e) => {
          let val = e.target.value;

          if (props.forceUppercase) val = val.toUpperCase();

          if (props.onChange && !touched && props.prefilled) {
            setTouched(true);
            if (isNotNull(props.value)) {
              val =
                val.length > props.value.length || val.length === 1
                  ? val[val.length - 1]
                  : "";
            }
          }

          if (props.validator) {
            const msg = props.validator(val);
            if (msg !== error) {
              e.target.setCustomValidity(msg);
              setError(msg);
              if (props.onError) props.onError(msg);
              if (showError && !msg) setShowError(false);
            }
          }

          if (props.onChange && !props.loading) props.onChange(val);
        }}
        aria-label={props.ariaLabel || props.placeholder}
        placeholder={props.placeholder}
        className={`
                   ${classes.control}
                   ${showError ? classes.error : ""}
               `}
      />
      <label className={classes.placeholder}>{props.placeholder}</label>
      <>
        {(props.icon || props.loading) && (
          <span className={classes.icon}>
            <i
              className={!props.loading ? props.icon : "fas fa-sync fa-spin"}
            />
          </span>
        )}
        {props.children}
      </>
      {showError && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default Input;

/*
export const Joined = (props: {
  className?: string;
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className={`${classes.joined} ${props.className}`}>
      {props.children}
    </div>
  );
};
*/
