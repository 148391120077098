import { useCallback, useEffect, useRef, useState } from "react";
import _ from "@/lodash";

import { Menu, MenuRef } from "./Menu";
import { arrayOf } from "@/helpers";
import { InputOpts, Option } from "@/types";

import classes from "./Select.module.scss";

const SelectLoader = (props: { count?: number }): JSX.Element => {
  return (
    <>
      {arrayOf(props.count ?? 3).map((v, i) => (
        <div key={i} className={classes.option + " " + classes.loading}>
          <span>...</span>
        </div>
      ))}
    </>
  );
};

const Select = <T extends any = any>(
  props: {
    id: string;
    name: string;
    className?: string;
    options: Option<T>[] | (() => Promise<Option<T>[]>);
    autoComplete?: boolean;
    autoSelect?: boolean | "always";
    disabled?: boolean;
    loading?: boolean;
    defaultIndex?: number;
    count?: number;
    allowNull?: boolean;
    allowNullOnChange?: boolean;
    nullText?: string;
    ariaLabel?: string;
  } & InputOpts<T>
): JSX.Element => {
  const menuRef = useRef<MenuRef>(null);

  /* inner state */
  const [selected, _setSelected] = useState<Option<T>>({
    text: props.valueText ?? "",
    value: props.value ?? null
  });
  const setSelected = useCallback(
    (o: Option<T>) => {
      _setSelected(o);
      /* onChange */
      const onChange = props.onChange;
      if (
        !!onChange &&
        (o.value !== null || props.allowNull || props.allowNullOnChange) &&
        !_.isEqual(props.value, o.value)
      )
        onChange(o.value as any);
    },
    [props.onChange, props.value, props.allowNull, props.allowNullOnChange]
  );

  /* load options */
  const [options, setOptions] = useState<Option<T>[]>([]);
  const loadOptions = useCallback(async () => {
    const o = props.options;

    if (!o) return;
    const opts = typeof o === "function" ? await o() : o;
    setOptions(opts);
    setShownOptions([]);

    const selectedOpt = _.find(opts, (opt) =>
      _.isEqual(opt.value, props.value)
    );
    setSelected(selectedOpt ?? { text: "", value: null });
  }, [props.options, props.value, setSelected]);

  /* default index */
  const [isSetDefaultIndex, setIsSetDefaultIndex] = useState(false);
  useEffect(() => {
    const i = props.defaultIndex;
    if (i !== undefined && options.length > i && !isSetDefaultIndex) {
      setSelected(options[i]);
      setIsSetDefaultIndex(true);
    }
  }, [props.defaultIndex, options, isSetDefaultIndex, setSelected]);

  /* auto complete */
  const [shownOptions, setShownOptions] = useState<Option<T>[]>([]);
  const onType = useCallback(
    (val: string) => {
      setShownOptions(
        options.filter((o) => o.text.toLowerCase().includes(val.toLowerCase()))
      );
    },
    [options]
  );

  /* only option auto select */
  const [allowAutoSelect, setAllowAutoSelect] = useState(props.autoSelect);
  const firstOption = options[0] !== undefined ? options[0] : null;
  useEffect(() => {
    if (
      props.autoSelect &&
      options.length === 1 &&
      firstOption !== null &&
      (allowAutoSelect || props.autoSelect === "always") &&
      !props.disabled
    )
      setSelected(firstOption);
  }, [
    props.autoSelect,
    allowAutoSelect,
    options.length,
    firstOption,
    setSelected,
    props.disabled
  ]);

  /* calculate options, based on auto complete & auto select */
  const finalOptions: Option<T>[] = [
    ...(props.allowNull
      ? [{ text: props.nullText ?? "- Any -", value: null }]
      : []),
    ...(shownOptions.length > 0 ? shownOptions : options)
  ];

  return (
    <Menu
      id={props.id}
      {...(props.autoComplete ? { onType } : {})}
      ref={menuRef}
      disabled={props.disabled}
      placeholder={props.name}
      className={props.className}
      menuClassName={classes.menu}
      loading={props.loading}
      loader={loadOptions}
      loaderInit={typeof props.options !== "function"}
      loaderComponent={<SelectLoader count={props.count} />}
      valueText={selected.text || props.nullText || ""}
      ariaLabel={props.ariaLabel}
    >
      {finalOptions.map((o) => (
        <div
          key={`${o.value}_${o.text}`}
          className={`${classes.option} ${
            _.isEqual(o.value, selected.value) ? classes.active : ""
          }`}
          onClick={() => {
            setSelected(o);
            if (o.value === null && props.allowNull) setAllowAutoSelect(false);

            menuRef.current!.switchShow();
          }}
        >
          {o.text}
        </div>
      ))}
    </Menu>
  );
};

export default Select;
