import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState
} from "react";

import Input from "./Input";
import Popup from "../Popup";
import { useIsMobile } from "@/helpers";

import classes from "./Menu.module.scss";

type MenuProps = {
  id: string;
  icon?: string;
  placeholder: string;
  disabled?: boolean;
  valueText: string;
  className?: string;
  menuClassName?: string;
  popupClassName?: string;
  loading?: boolean;
  loader?: () => Promise<any>;
  loaderInit?: boolean;
  loaderComponent?: ReactNode;
  children?: ReactNode;
  onType?: (value: string) => void;
  mobilePopup?: boolean;
  ariaLabel?: string;
};

export type MenuRef = { switchShow: () => void };

export const Menu = React.forwardRef<MenuRef, MenuProps>(function Menu(
  {
    icon = "fas fa-chevron-down",
    id,
    valueText,
    placeholder,
    children,
    className,
    menuClassName = classes.menu,
    popupClassName,
    loading,
    loader,
    loaderInit = true,
    loaderComponent = <p>Loading...</p>,
    onType,
    disabled,
    mobilePopup,
    ariaLabel
  },
  ref
): JSX.Element {
  const isMobile = useIsMobile();

  const [showMenu, switchShow] = useMenu(id, mobilePopup && isMobile);
  useImperativeHandle(ref, () => ({
    switchShow
  }));

  /* loader */
  const [loaded, setLoaded] = useState(!loader);
  useEffect(() => {
    if (!!loader && (showMenu || loaderInit)) {
      Promise.resolve(loader()).then(() => setLoaded(true));
    }
  }, [loader, showMenu, loaderInit]);

  /* autocomplete */
  const [typed, setTyped] = useState<null | string>(null);
  useEffect(() => {
    if (typed === null) return;
    if (onType) onType(typed);
  }, [typed, onType]);

  const isReadOnly = !onType;

  const menuContent = (
    <div className={menuClassName + " " + (showMenu ? "" : "d-none")}>
      {loaded ? children : loaderComponent}
    </div>
  );

  return (
    <Input
      readOnly={isReadOnly}
      id={id}
      loading={loading}
      placeholder={placeholder}
      ariaLabel={ariaLabel}
      className={className}
      disabled={disabled}
      clickable
      onChange={setTyped}
      onInputBlur={() => {
        setTyped(null);
      }}
      onClick={() => {
        if (isMobile) switchShow();
      }}
      onFocus={() => {
        if (!isMobile) switchShow(true);
      }}
      onBlur={(isInside) => {
        if (!isInside && !isMobile) switchShow(false);
      }}
      value={typed !== null ? typed : valueText}
      icon={icon}
    >
      {mobilePopup && isMobile ? (
        <Popup
          contentClassName={className + " " + popupClassName}
          open={showMenu}
          onClose={() => switchShow()}
          backdropClose={false}
        >
          {menuContent}
        </Popup>
      ) : (
        menuContent
      )}
    </Input>
  );
});

const useMenu = (
  id: string,
  isMobile?: boolean
): [boolean, (val?: boolean) => void] => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const fn = (ev: MouseEvent) => {
      const container = document.getElementById(id);
      if (container !== ev.target && !container?.contains(ev.target as Node)) {
        setShowMenu(false);
      }
    };

    showMenu && !isMobile
      ? document.addEventListener("click", fn)
      : document.removeEventListener("click", fn);
    return () => document.removeEventListener("click", fn);
  }, [id, showMenu, setShowMenu, isMobile]);

  return [
    showMenu,
    (val?: boolean) => setShowMenu((o) => (val === undefined ? !o : val))
  ];
};
