import _ from "@/lodash";

import { moveWithMouse } from "@/helpers";
import { InputOpts } from "@/types";

import classes from "./Radio.module.scss";
import { ReactNode } from "react";
import moment from "moment-timezone";

const Radio = <T extends any = any>(
  props: {
    text: ReactNode;
    group?: string;
    checkValue?: T;
    successValue?: T;
    unselectValue?: any;
    className?: string;
    required?: boolean;
    button?: boolean;
  } & InputOpts<T>
): JSX.Element => {
  const checked = moment.isMoment(props.value)
    ? props.value.isSame(props.checkValue as moment.Moment)
    : _.isEqual(props.checkValue, props.value);
  const success = _.isEqual(props.checkValue, props.successValue) && checked;

  const canUnselect = props.unselectValue !== undefined;

  return (
    <label
      className={`
            ${props.className}
            ${props.button ? classes.radioButton : classes.radio} 
            ${checked ? classes.checked : ""}
            ${props.required ? classes.required : ""}
            ${success ? classes.success : ""}  
        `}
      {...moveWithMouse(".ra")}
    >
      <input
        type="radio"
        name={props.group}
        onChange={() => {}}
        required={props.required}
        checked={checked}
        onClick={() => {
          const newVal =
            checked && canUnselect ? props.unselectValue : props.checkValue;

          props.onChange && newVal !== undefined
            ? props.onChange(newVal)
            : null;
        }}
      />
      <span className={classes.checkmark} />
      <div className={classes.radioText}>{props.text}</div>
      {props.button && <span className={classes.radioActive + " ra"} />}
    </label>
  );
};

export default Radio;
